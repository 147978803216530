#screenshot-overlay {
    background-color: rgba(0, 0, 0, 0.8);
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    display: flex;

    justify-content: center;
    align-items: center;

    flex-direction: column;

}

#screenshot-overlay-container {
    display: flex;
    overflow: hidden;
    flex-direction: column;
    height: 85vh;

}


#screenshot-overlay-image {
    display: flex;
    object-fit: contain;
    height: 90%;

    z-index: 2;
}

#screenshot-overlay-caption {
    display: flex;

    align-items: center;
    justify-content: center;

    font-weight: bolder;
    text-align: center;


    height: 3em;
    border-radius: 16px;
}

#screenshot-overlay-close {
    opacity: 1;
    position: absolute;
    right: 2vw;
    top: 1vw;
}
