#section-header-container {
    display: block;
}

.section-header {
    width: 100vw;
    height: 10vh;

    font-size: 40pt;
    font-weight: bolder;

    padding-bottom: 24px;
    padding-top: 64px;

    margin-bottom: 24px;

    vertical-align: center;
    line-height: 10vh;
}



.section-header-text  {

}



.item {
    display: flex;
}
