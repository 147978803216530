
/*
    education-blobs
*/

.education-blob {
    margin-bottom: 24px;
    position: relative;

}

.education-blob h1 {
    text-align: left;
    margin: 0;
    padding: 0;
}

.education-blob h2 {
    border-bottom: 4px;
    border-bottom-style: solid;
    border-bottom-color: #ab000e;

    margin: 0;
    padding: 0;
}

#blob-image img {
    width: 75px;
    border-radius: 20px;

    float: left;
    margin-right: 16px;
}

.blob-content {
    display: flex;
    flex-direction: column;

    padding: 16px;
    border-radius: 8px;
    background-color: var(--color-primary-dark);

}

.desc-item {
    width: 100%;
    overflow: hidden;
    color: white;
}

.desc-item p:first-child {
    margin: 0;
    padding: 30px 0px 5px 0px;
}

.desc-item p :after {
    margin: 0;
    padding: 5px 0px 5px 0px;
}

/*
    Content IDs
*/

#blob-text-institute {
    font-size: 24pt;
    font-weight: bold;
}

#blob-text-graduation {
    font-size: 16pt;
    font-weight: bold;
    float: right;
}

@media only screen and (max-width: 1360px) {
    #blob-image img {
        width: 50px;
    }
}

#blob-text-course {
    margin-top: 12px;

    font-size: 18pt;
}

#blob-text-grade {
    font-size: 16pt;
    font-weight: bold;
}


.button_expand {
    margin-top: 16px;
}





