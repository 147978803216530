.social-button-size {
    width: 50px;
    height: 50px;
}

.social-button {
    width: 50px;
    height: 50px;
    line-height: 50px;

    display: inline-flex;

    padding: 20px;
    margin-right: 16px;

    border-radius: 100px;

    cursor: pointer;
    z-index: 1;

    background-color: var(--color-primary);

    transition: box-shadow 100ms linear;
    box-shadow: 0 0 0 var(--color-primary-dark);
    text-align: center;

    position: relative;
}

.social-button img {
    z-index: 1;
    float: left;
}

.social-button:hover {
    box-shadow: 0 0 12px #4b4b4b;
}
