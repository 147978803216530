
:root {
    --padding-large: 80px;
    --header-height: 60vh;
}

@keyframes backgroundAnim {
    from {
        background-position-x: 0px;
        background-position-y: 0px;
    }
    to {
        background-position-x: 60000%;
        background-position-y: 120000%;
    }
}

header {
    display: flex;
    flex-direction: column;

    padding-top: var(--padding-large);
    padding-bottom: var(--padding-large);
    padding-left: 15%;

    user-select: none;
    font-size: 18pt;

    background: url("../res/img/grid.png");
    background-repeat: repeat;


    animation-duration: 60000s;
    animation-name: backgroundAnim;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    background-size: 100px;
}

header * {
    z-index: 1;

}


header h1 {
    margin-top: 20px;
    margin-bottom: 20px;

    font-size: 48pt;
    text-align: left;
}

#intro-text {
    background-color: var(--color-primary-dark);
    padding: 20px;
    width: 40vw;
    border-radius: 16px;

    font-size: 16pt;

    margin-top: 24px;
    margin-bottom: 24px;
}



#intro-text p {
    text-align: left;
    margin-bottom: 24px;
}

.social-container {

}


.section {
    /*background-color: #ab000e;*/
    margin-bottom: 48px;

    margin-left: 10vw;
    margin-right: 10vw;
}

.section:after {
    margin-top: 24px;
}




.checkbutton {
    background-color: #575757;
    border-radius: 50px;
    text-align: center;
    height: 50px;
    line-height: 50px;
    user-select: none;
    cursor: pointer;
    margin-left: 5px;
    margin-right: 5px;

    width: auto;

    white-space: nowrap;
    display: inline-block;

    min-height: 1em;
    font-weight: bold;
    font-size: 15pt;

    padding-left: 1.5em;
    padding-right: 1.5em;
}

#project-container {
    padding-top: 28px;
    display: flex;

    flex-wrap: wrap;


}

