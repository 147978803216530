.button {
    background-color: #3e3e3e;
    border-radius: 50px;
    text-align: center;
    height: 40px;
    line-height: 40px;
    user-select: none;
    cursor: pointer;

    margin-right: 5px;

    /*box-shadow: 2px 6px #494949;*/

    width: auto;

    align-content: center;

    white-space: nowrap;
    display: inline-flex;
    min-height: 1em;
    font-weight: bold;
    font-size: 15pt;

    /*box-shadow: 2px 4px #494949;*/


    padding-left: 1em;
    padding-right: 1em;
    padding-top: 0.1em;
    padding-bottom: 0.1em;

}

.invert { filter: invert(100%); }

.buttonImg {
    display: flex;
    line-height: 40px;
}

.buttonImg img {
    object-fit: contain;
    height: 20px;
    align-self: center;
}
