:root {
  --color-bg: #181a1b;
  --color-primary: rgb(42, 42, 42);
  --color-primary-dark: #272727;
  --color-primary-accent: #e53935;
  --color-secondary: #e53935;
  --color-secondary-light: #ff6d60;
  --color-secondary-dark: #ab000e;
  color: white;
}

html, body {
  margin: 0;
  height: 100%;
  width: 100vw;
  overflow-x: hidden;

  background-color: var(--color-bg);

}

a:link { color: #fff; text-underline-offset: 5px}
a:visited { color: #fff }
a:hover { color: var(--color-secondary-light) }
a:active { color: var(--color-primary-accent) }


.text-center {
  text-align: center;
}

.flex-center {
  align-self: center;
}

.flex-end {
  align-self: flex-end;
}

.float-right {
  float: right;
}

.shadow {
  box-shadow: 0 0 24px #212121;
}

.text-shadow {
  text-shadow: 0 0 16px var(--color-primary-dark);
}


