:root {
    --color-bg: #212121;
    --color-primary: rgb(42, 42, 42);
    --color-primary-dark: #272727;
    --color-primary-accent: #c11c1c;
    --color-secondary: #e53935;
    --color-secondary-light: #ff6d60;
    --color-secondary-dark: #ab000e;
    color: white;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.hidden {
    flex-grow: 0.0001;
}

#description_container {
    display: flex;
    flex-direction: row;
    flex-grow: 1;

    padding-top: 1em;
}


#project {
    max-width: 500px;
    min-width: 500px;

    display: flex;

    margin-right: 24px;
    margin-bottom: 24px;

    padding: 32px;
    background-color: var(--color-primary);

    border-radius: 16px;

    vertical-align: middle;

}

#title {
    text-align: left;
    font-size: 16pt;
    font-weight: 700;

    padding-bottom: 5px;

    border-style: solid;
    border-width: 0 0 0 0;
    border-color: var(--color-primary) var(--color-primary) var(--color-primary-accent) var(--color-primary);
}

#stack {
    font-size: 12pt;
    display: block;
    color: gray;
    margin: 0
}

#image {
    min-width: 150px;
    max-width: 150px;

    height: 150px;
    font-size: 80px;
    border-radius: 25px;
    background-color: var(--color-primary-dark);
    display: block;
    padding-top: 0;
    line-height: 150px;

    align-self: center;
    text-align: center;
}

#image img {
    min-width: 100px;
    width: 150px;
    height: 150px;

    object-fit: fill;

    border-radius: 25px;
}

#description {
    padding-left: 2em;

    font-size: 14pt;

    line-height: 120%;
    text-align: left;
}

#linkToGitHub {
    float: right;
    font-size: 12pt;

    outline: white;

    border: 1px solid white;
    border-radius: 8px;
    padding: 5px 10px 5px 10px;

    color: white;

    background-color: var(--color-primary);

    user-select: none;

    text-decoration: none;

    transition: 0.3s;
}

#linkToGitHub img {
    height: 1.3em;
    margin-left: 4px;
    vertical-align: text-bottom;
}

#linkToGitHub:hover {
    filter: invert(100%);
}

#linkToGitHub:active {
    filter: invert(25%);
}

#linkToGitHub:visited {
    color: white;
}
